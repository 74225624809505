import service from '@/utils/request2'

//获取地理信息
export function getAddress(params){
    return service({
        url:'/api/api/area',
        method:'get',
        params
    })
}
//获取专业
export function getMajorList(params){
    return service({
        url:'/api/api/major',
        method:'get',
        params
    })
}

//添加报名
export function addEnrollment(data){
    return service({
        url:'/api/api/examination/join',
        method:'post',
        data
    })
}

//支付
export function pay(data){
    return service({
        url:'/api/api/pay',
        method:'post',
        data
    })
}

//支付
export function isPay(data){
    return service({
        url:'/api/api/examination/getOrderStatus',
        method:'post',
        data
    })
}