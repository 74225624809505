<template>
    <div>
        <div class="ExamListList">
            <div>
                <div>
                    <img :src="ExamDetails.cover_src" alt="" width="100%" height="100%">
                </div>
                <div>
                    <p class="fs18">{{ ExamDetails.examination_title }}</p>
                    <p class="mt20"> 考试专业：{{ ExamDetails.major_name }}</p>
                    <p>报名时间：{{ ExamDetails.join_star_time }}</p>
                    <p>考试时间：{{ ExamDetails.examination_star_time }}</p>
                    <p class="mt20">报名费用：{{ ExamDetails.sign_price }}</p>
                </div>
            </div>
        </div>
        <div class="content" v-if="ExamDetails.file_src">
            <img src="@/../public/image/word.png" width="46px" height="54px" alt="">
            <p class="ml15">中国书画文化发展促进会产业专业委员会线上考试须知</p>
            <div @click="downloadTemplate('file_src')">
                查看
            </div>
        </div>
        <div class="content content2" v-else>
            <img src="@/../public/image/pdf.png" width="46px" height="54px" alt="">
            <p class="ml15">中国书画文化发展促进会产业专业委员会线上考试须知</p>
            <div @click="downloadTemplate('cover_src')">
                查看
            </div>
        </div>
        <div class="start" v-if="ExamDetails.examination_status == '报名考试'" @click="GoRegister">
            报名考试
        </div>
        <div class="start" v-else-if="ExamDetails.examination_status == '前往考试'"
            @click="$router.push(`/examination?id=${ExamDetails.examination_id}&star=${ExamDetails.examination_star_time}&end=${ExamDetails.examination_end_time}`)">
            前往考试
        </div>
        <div class="end" v-else-if="ExamDetails.examination_status == '报名费用未支付,请点击进行支付'" @click="getIs_pay">
            {{ ExamDetails.examination_status }}
        </div>
        <div class="end" v-else>
            {{ ExamDetails.examination_status }}
        </div>


        <div v-if="is_show == 1" class="dialogBox">
            <!--  选择报名专业 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">请选择报名专业</p>
                <div class="form">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-form-item label="考试专业 ： " label-width="120px">
                            <el-select v-model="form.region" placeholder="请选择报名的专业">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btnBox">
                    <div class="cancel " @click="is_show = 0">取消</div>
                    <div class="ml15 Submit">前往报名</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 2" class="dialogBox">
            <!--  选择报名专业 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">请选择报名专业</p>
                <div class="form">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-form-item label="考试专业 ： " label-width="120px" prop="region">
                            <el-select v-model="form.region" placeholder="请选择报名的专业">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btnBox">
                    <div class="cancel " @click="is_show = 0">取消</div>
                    <div class="ml15 Submit" @click="GoRegister('form')">前往报名</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 3" class="dialogBox RegistrationInformation">
            <!--  填写报名信息 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">请填写报名信息</p>
                <div class="form">
                    <el-form :model="ruleForm" :rules="rules2" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                        <el-form-item label="姓名 ： " prop="student_name">
                            <el-input v-model="ruleForm.student_name" placeholder="请输入您的姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="性别 ： " prop="sex">
                            <el-select v-model="ruleForm.sex" placeholder="请选择您的性别">
                                <el-option label="男" value="1"></el-option>
                                <el-option label="女" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身份证 ： " prop="idcard">
                            <el-input v-model="ruleForm.idcard" placeholder="请输入您的身份证"></el-input>
                        </el-form-item>
                        <el-form-item label="联系地址" prop="province_id">
                            <div class="address">
                                <el-form-item prop="province_id">
                                    <el-select v-model="ruleForm.province_id" placeholder=""
                                        @change="getAddressFn('cityList', ruleForm.province_id)" ref="province">
                                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in provinceList"
                                            :key="index" v-if="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="city_id">
                                    <el-select v-model="ruleForm.city_id" placeholder=""
                                        @change="getAddressFn('regionList', ruleForm.city_id)" ref="city">
                                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in cityList"
                                            :key="index" v-if="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="area_id">
                                    <el-select v-model="ruleForm.area_id" placeholder="" ref="area">
                                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in regionList"
                                            :key="index" v-if="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form-item>

                        <el-form-item label="" prop="address">
                            <el-input type="textarea" v-model="ruleForm.address" placeholder="请输入您的详细地址（证书邮寄地址）"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式 ： " prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="请输入您的联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="学历 ： " prop="education">
                            <el-select v-model="ruleForm.education" placeholder="请选择您的学历">
                                <el-option label="专科" value="1"></el-option>
                                <el-option label="本科" value="2"></el-option>
                                <el-option label="研究生" value="3"></el-option>
                                <el-option label="高中" value="4"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="专业" prop="major1">
                            <div class="major">
                                <el-form-item prop="major1">
                                    <el-select v-model="ruleForm.major1" placeholder="请选择一级专业"
                                        @change="getMajorListFn('majorList', ruleForm.major1)">
                                        <el-option :label="item.major_name" :value="item.major_id"
                                            v-for="(item, index) in majorList1" :key="index" v-if="item.major_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="major_id">
                                    <el-select v-model="ruleForm.major_id" placeholder="请选择一级专业" ref="major_name">
                                        <el-option :label="item.major_name" :value="item.major_id"
                                            v-for="(item, index) in majorList" :key="index" v-if="item.major_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <el-form-item label="证件照" prop="idphoto_src">
                            <div style="width:446px;">
                                <div class="imgBox">
                                    <Upload :imgUrl="ruleForm.idphoto_src" @change="getImg" />
                                </div>
                            </div>
                        </el-form-item>
                        <div class="poRe">
                            <el-form-item label="学历证明/学生证" prop="educationphoto_src" label-width="200px">
                                <div style="width:446px;">
                                    <div class="imgBox">
                                        <Upload :imgUrl="ruleForm.educationphoto_src" @change="getImg2" />
                                    </div>
                                </div>
                            </el-form-item>
                        </div>

                    </el-form>
                </div>
                <div class="btnBox">
                    <!-- <div class="cancel " @click="is_show -= 1">上一步</div> -->
                    <div class="ml15 Submit" @click="SubmitInformation('ruleForm')">提交报名信息</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 4" class="dialogBox">
            <!--  微信支付 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">请支付订单</p>
                <div class="QRcodeBox">
                    <div>
                        <img :src="code.code_url" width="100%" height="100%" alt="" v-if="code.code_url">
                    </div>
                    <div>

                    </div>
                    <div>
                        <p class="fs24"><img src="@/../public/image/vxzf.png" alt="" class="mr15">微信支付</p>
                        <p class="fs20 mt20">请使用微信扫码左侧</p>
                        <p class="fs20">二维码完成费用支付</p>
                        <p class="fs30 mt20" v-if="code.is_member == 1">￥{{ code.fee }}（会员价）</p>
                        <p class="fs30 mt20" v-else>￥{{ ExamDetails.sign_price }}（原价）</p>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 5" class="dialogBox">
            <!--  支付成功 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <img src="@/../public/image/cg.png" width="113px" alt="">
                    <p class="fs22">支付成功</p>
                    <p class="mt20">您的订单已成功支付</p>
                    <p>请注意在考试时间进行考试</p>
                </div>
                <div class="btnBox">
                    <div class="complete" @click="is_show = 0">完成</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 6" class="dialogBox">
            <!--  支付失败 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <img src="@/../public/image/sb.png" width="113px" alt="">
                    <p class="fs22">支付失败</p>
                    <p class="mt20">您的订单支付失败</p>
                </div>
                <div class="btnBox">
                    <div class="complete" @click="is_show = 0">完成</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 7" class="dialogBox">
            <!--  提示 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="dialogTitle">
                    <p class="fs22">当前账号已有报名信息。</p>
                </div>

                <div class="btnBox">
                    <div class="complete">关闭</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 8" class="dialogBox">
            <!--  另一个审核 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">您的所学专业与报名专业不符</p>
                <p class="title">我们需要进行人工审核</p>
                <div class="form">
                    <el-form ref="form" :model="form" label-width="120px">
                        <el-form-item label="姓名 ： " prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式 ： " prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入您的联系方式"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btnBox">
                    <div class="cancel ">取消</div>
                    <div class="ml15 Submit">提交</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 9" class="dialogBox">
            <!--  成功提交 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="dialogTitle">
                    <p class="fs22">您的考试报名申请已提交，请耐心等待人工审核。</p>
                </div>

                <div class="btnBox">
                    <div class="complete">关闭</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show == 10" class="dialogBox">
            <!--  报名考试 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">请输入您的报名信息</p>
                <div class="form">
                    <el-form ref="form" :model="form" label-width="120px">
                        <el-form-item label="姓名 ： " prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式 ： " prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入您的联系方式"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btnBox">
                    <div class="complete" @click="$router.push('/examination')">进入考场</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getExamDetails } from '@/api/ExamList'
import { getAddress, getMajorList, addEnrollment, pay, isPay } from '@/api/signupExamination'
import Router from '@/router/index'
export default {
    data() {
        return {
            page_size: 10,
            page: 1,
            total: 20,
            dialogVisible: true,
            is_show: 0,
            ExamDetails: {},
            code: '',
            is_pay: true,
            companyInfo: {},
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            ruleForm: {
                city_id: '',
                province_id: '',
                area_id: '',
                idphoto_src: '',
                educationphoto_src: '',
                major_id: ''
            },
            //省份列表
            provinceList: [

            ],
            //城市列表
            cityList: [],
            //地区列表
            regionList: [],
            majorList1: [],
            majorList: [],
            rules: {
                region: [
                    { required: true, message: '请选择报名的专业', trigger: 'change' },
                ],
            },
            num: 0,
            time: null,
            rules2: {
                student_name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                ],
                sex: [
                    { required: true, message: '请选择您的性别', trigger: 'change' },
                ],
                idcard: [
                    { required: true, message: '请输入您的身份证', trigger: 'blur' },
                ],

                province_id: [
                    { required: true, message: '请选择省份', trigger: 'change' },
                ],
                city_id: [
                    { required: true, message: '请选择城市', trigger: 'change' },
                ],
                area_id: [
                    { required: true, message: '请选择区域', trigger: 'change' },
                ],
                address: [
                    { required: true, message: '请输入您的详细地址（证件邮寄地址）', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入您的联系方式', trigger: 'blur' },
                ],
                education: [
                    { required: true, message: '请选择您的学历', trigger: 'change' },
                ],
                major_id: [
                    { required: false, message: '请输入您的专业', trigger: 'blur' },
                ],
                major1: [
                    { required: true, message: '请输入您的专业', trigger: 'blur' },
                ],
                idphoto_src: [
                    { required: true, message: '请上传证件照', trigger: 'change' },
                ],
                educationphoto_src: [
                    { required: true, message: '请上传学位证书', trigger: 'change' },
                ],
            }
        }
    },
    mounted() {
        if (localStorage.getItem('student_id')) {
            this.payFn()
            this.is_show = 4
        }
        this.getExamDetailsFn()
        this.getAddressFn('provinceList')
        this.getMajorListFn('majorList1')

    },
    methods: {
        pageChange(val) {
        },
        sizeChange(val) {
        },
        getImg(val) {
            this.ruleForm.idphoto_src = val
        },
        getImg2(val) {
            this.ruleForm.educationphoto_src = val
        },
        //获取考试详情
        getExamDetailsFn() {
            getExamDetails({ examination_id: this.$route.query.id }).then(res => {
                if (res.code == 200) {
                    this.ExamDetails = res.data
                    if (localStorage.getItem('is_pay')) {
                        localStorage.setItem('student_id', this.ExamDetails.student_id)
                        this.payFn()
                        this.is_show = 4
                    }
                }
            })
        },
        //前往报名
        GoRegister(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.is_show = 3

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //提交报名信息
        SubmitInformation(formName) {
            this.ruleForm.province_name = this.$refs.province.selectedLabel
            this.ruleForm.city_name = this.$refs.city.selectedLabel
            this.ruleForm.area_name = this.$refs.area.selectedLabel
            this.ruleForm.examination_id = this.$route.query.id
            this.ruleForm.major_name = this.$refs.major_name.selectedLabel
            this.ruleForm.major_id = this.ruleForm.major_id ? this.ruleForm.major_id : this.ruleForm.major1
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addEnrollment(this.ruleForm).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功');
                            localStorage.setItem('student_id', res.data.student_id)
                            this.is_show = 4
                            this.payFn()
                            setTimeout(() => {
                                window.location.reload()
                            }, 500);

                        } else if (res.code == 402) {
                            this.$message.error('报名专业不符合');
                            // localStorage.setItem('student_id', res.data.student_id)
                            // this.is_show = 4
                            // this.payFn()
                        }
                    })
                } else {
                    this.$message.error('报名信息不全');
                    return false;
                }
            });
        },
        //获取地理信息
        getAddressFn(list, id) {
            getAddress({ pid: id }).then(res => {
                this[list] = res.data
            })
        },
        //获取专业信息
        getMajorListFn(list, id) {
            getMajorList({ pid: id }).then(res => {
                this[list] = res.data
            })
        },
        //获取支付二维码
        payFn() {
            pay({ student_id: localStorage.getItem('student_id') }).then(res => {
                if (res.code) {
                    this.code = res.data
                    this.is_payFn()
                }
            })
        },
        //判断是否支付
        is_payFn() {
            this.num++
            isPay({ student_id: localStorage.getItem('student_id') }).then(res => {
                if (res.code == 200) {
                    this.is_show = 5
                    this.is_pay = false
                } else {
                    if (this.is_pay) {
                        this.time = setTimeout(() => {
                            this.is_payFn()
                        }, 5000);
                    }
                }
            })

            if (this.num > 100) {
                this.is_show = 6
                this.is_pay = false
            }
        },
        //去报名
        GoRegister() {
            // console.log(111)
            if (localStorage.getItem('userid')) {
                if (localStorage.getItem('student_id')) {
                    this.is_show = 4
                } else {
                    this.is_show = 3
                }
            } else {
                localStorage.removeItem('sheixetoken')
                localStorage.removeItem('oneFrom')
                localStorage.removeItem('twoFrom')
                localStorage.removeItem('threeFrom')
                localStorage.removeItem('oneFrom1')
                localStorage.removeItem('twoFrom1')
                localStorage.removeItem('oneFrom2')
                localStorage.removeItem('twoFrom2')
                localStorage.removeItem('userid')
                Router.replace({
                    path: '/login',
                    query: {
                        redirect: window.location.hash.replace('#', '')
                    }
                });

            }

        },
        // 导出
        //下载模板
        downloadTemplate(data) {
            var tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = this.ExamDetails[data];
            tempLink.setAttribute("download", "考试须知");
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        },
        getIs_pay() {
            // console.log(111)
            localStorage.setItem('is_pay', true)
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    },
    watch: {
        dialogVisible(val) {
            if (val == false) {
                this.is_show = 0
                this.dialogVisible = true
            }
        },
        'ruleForm.province_id': {
            handler() {
                this.ruleForm.city_id = ''
                this.ruleForm.area_id = ''
            },
            immediate: true,

        },
        'ruleForm.city_id'() {
            this.ruleForm.area_id = ''
        },
        'ruleForm.major1'() {
            this.ruleForm.major_id = ''
        }

    },
    beforeRouteLeave(to, from, next) {
        localStorage.removeItem('student_id')
        localStorage.removeItem('is_pay')
        clearInterval(this.time);
        this.time = null;
        next()
    }
}
</script>

<style  scoped>
.ExamListList>div>div:nth-child(3) p {
    width: 100%;
}


.ExamListList>div {
    display: flex;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
}

.ExamListList>div>div:nth-child(1) {
    width: 220px;
    height: 160px;
    background: #CD0000;
}

.ExamListList>div>div:nth-child(2) {
    color: #000000;
    margin-left: 20px;
    font-size: 14px;
}




input:focus {
    outline: none;
}

.ExamListList>div>div:nth-child(3) {
    margin-left: auto;
    width: 110px;
    text-align: right;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}

.RegistrationInformation>>>.el-dialog {
    height: 746px;
    overflow-y: auto;
    overflow-x: hidden;
}

.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.cancel {
    width: 200px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.Submit {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.title {
    text-align: center;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
}

.content {
    height: 88px;
    border: 1px solid #4A8DFF;
    border-radius: 4px;
    margin: 30px auto;
    display: flex;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #4A8DFF;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
}

.content2 {
    border: 1px solid #ff4867 !important;
    color: #ff4867 !important;
}

.content2>div {
    background: #ff4867 !important;
}

.content>div {
    width: 90px;
    height: 40px;
    background: #4A8DFF;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    cursor: pointer;
    margin-left: auto;
}

.form {
    display: flex;
    margin: 20px 0;
    justify-content: center;
}

.form>>>.el-input__inner {
    width: 446px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.form>>>.el-textarea__inner {
    width: 446px;
    height: 157px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.form>>>.el-form-item__label {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.form>>>.demo-ruleForm {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.address {
    display: flex;
    width: 446px;
    justify-content: space-between;
}

.address>>>.el-input__inner {
    flex: 1;
    width: 138px !important;
}

.major {
    display: flex;
    width: 446px;
    justify-content: space-between;
}

.major>>>.el-input__inner {
    flex: 1;
    width: 200px !important;
}

.imgBox {}

.QRcodeBox {
    margin: 40px auto;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.QRcodeBox>div:nth-child(1) {
    width: 190px;
    height: 190px;
    background: #FFFFFF;
    border: 1px solid #CC0000;
}

.QRcodeBox>div:nth-child(2) {
    width: 1px;
    height: 160px;
    background: #D4D4D4;
}

.QRcodeBox>div:nth-child(3) p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.QRcodeBox>div:nth-child(3) p:nth-child(4) {
    color: #CC0000;
}

.complete {
    width: 440px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.PaymentSucceeded {
    text-align: center;
    margin-bottom: 20px;
}

.dialogTitle {
    height: 100px;
}

.start {
    width: 510px;
    margin-top: auto;
    height: 48px;
    background: #CD0000;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    cursor: pointer;
}

.end {
    cursor: pointer;
    width: 510px;
    height: 48px;
    background: #B1B1B1;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    margin: 0 auto;
    text-align: center;
    line-height: 48px;
}

.poRe {
    position: relative;
    left: -40px;
}
</style>